












import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Tab from '@/store/entities/admin/tab';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageTabRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class Tabs extends ListBase {
    pagerequest: PageTabRequest = new PageTabRequest();
    tab: Tab = new Tab();
    modalSetting: any = {
        entity: "tab",
        title: 'Menu',
        width: "700px"
    };

    edit() {
        this.modalShow = true;
    }
    create() {
        this.modalShow = true;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }
    items = [
        this.gItem({ type: 'object', prop: 'parent_id', label: 'Padre', required: true, entity: 'tab', size: 4 }),
        this.gItem({ type: 'text', prop: 'title', label: 'Titulo', required: true, size: 8 }),
        this.gItem({ type: 'text', prop: 'code', label: 'Codigo', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'icon', label: 'Icono', size: 4 }),
        this.gItem({ type: 'active' })
    ]
    columns = this.gColumns(this.items, 'code,name')
    async created() {
        //this.getpage();
    }
}